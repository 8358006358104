import React, { useState } from "react";

import AlertDialog from "../../alert-dialog";
import ReturnTripForm from "./returnTripForm";
import { useDispatch, useSelector } from "react-redux";
import { tripActions } from "../../../redux/actions/trip_actions";
import moment from "moment";

export default function ReturnTrip({ open }) {
    const [time, setTime] = useState(null)
    const [isRequestByDropOff, setRequestByDropOff] = useState(false)
    const isPending = useSelector((state) => state.tripReducer.pending)

    const dispatch = useDispatch()
    const tripReducer = useSelector((state) => state.tripReducer)
    const customerReducer = useSelector((state) => state.customerReducer)
    const { tripToReturn } = tripReducer

    const onClose = () => {
        dispatch(tripActions.cleanRepeatTrip())
    }

    const combineDateWithTime = (date, time) => {
        let day = date.date();
        let month = date.month();
        let year = date.year();

        let hour = time.hour();
        let minute = time.minute();
        let second = time.second();

        return moment({ year, month, day, hour, minute, second });
    }

    const onSubmit = (e) => {
        e.preventDefault()
        const prevTime = moment(tripToReturn.minPickUpTime)
        const newTime = moment(time)

        const combinedDate = combineDateWithTime(prevTime, newTime)

        const returnTrip = {
            customerId: tripToReturn.customerId,
            pickUpLocation: {
                address: tripToReturn.pickUpLocation.location.address,
                lat: tripToReturn.pickUpLocation.location.lat,
                lon: tripToReturn.pickUpLocation.location.lng,
            },
            dropOffLocation: {
                address: tripToReturn.dropOffLocation.location.address,
                lat: tripToReturn.dropOffLocation.location.lat,
                lon: tripToReturn.dropOffLocation.location.lng,
            },
            isRequestByDropOff: isRequestByDropOff,
            requestPickUpStartTime: combinedDate,
            pickupEndTime: moment(combinedDate).add(15, 'minutes'),
            numPassengers: tripToReturn.numPassengers,
            customerExpoPushToken: customerReducer.customerPushToken,
            customerNotificationLanguage: customerReducer.langNotification,
            luggage: parseInt(tripToReturn.quantityLuggage),
            hasWheelChair: tripToReturn.wheelChairs,
            customerDateOfBirth: tripToReturn.customerToRepeat.isOnRelatedCustomerBehalf ? tripToReturn.customerToRepeat.parentBirthDate : tripToReturn.customerToRepeat.birthDate,
            customerName: tripToReturn.customerToRepeat.isOnRelatedCustomerBehalf ? tripToReturn.customerToRepeat.customerParentName : tripToReturn.customerToRepeat.name,
            isOnRelatedCustomerBehalf: tripToReturn.customerToRepeat.isOnRelatedCustomerBehalf,
            relatedCustomer: tripToReturn.customerToRepeat.isOnRelatedCustomerBehalf
                ? {
                    id: tripToReturn.customerToRepeat.customerIdRelated,
                    name: tripToReturn.customerToRepeat.name,
                    dateOfBirth: tripToReturn.customerToRepeat.birthDate,
                }
                : null,
            passengerId: null,
            serviceLineDirection: null,
            isReturn: true,
            outboundTripId: tripToReturn.tripId
        }


        dispatch(tripActions.getTripServices(
            returnTrip,
            tripToReturn.customerId,
            false,
            false,
        ))

        dispatch(tripActions.closeReturnTripModal())
    }

    return (
        <AlertDialog
            open={open}
            onClickCancel={onClose}
            onClickAccept={onSubmit}
            conditionDisableAccept={time == null || isPending}
            conditionDisableCancel={isPending}
            maxWidth={'xs'}
            fullWidth
            content={() =>
                <ReturnTripForm
                    time={time}
                    setTime={setTime}
                    isRequestByDropOff={isRequestByDropOff}
                    setRequestByDropOff={setRequestByDropOff}
                    onSubmit={onSubmit}
                />}
        />

    )
}