import i18next from 'i18next'
import { customerConstants } from '../../constants/customer_constants'
import { notificationsConstants } from '../../constants/notifications_constants'
import { tripConstants } from '../../constants/trip_constants'
import { factory } from '../../helpers/factory'
import { SERVICES_TYPE } from '../../constants/types'
import moment from 'moment'

let initialState = {
    pending: false,
    results: [],
    markers: [],
    modalSelectedService: false,
    modalReturnTrip: false,
    modalExpeditions: false,
    originStop: null,
    destinationStop: null,
    services: [],
    typesTrips: [],
    originDistanceTime: null,
    destinationDistanceTime: null,
    filterStartDate: new Date(),
    filterEndDate: new Date(),
    tripsKPI: [],
    allTrips: [],
    filterTrips: [{
        status: []
    }],
    requestTripError: false,
    tableFilters: [],
    isReturn: false,
    tripToReturn: null,
}

export function tripReducer(state = initialState, action) {
    switch (action.type) {
        case tripConstants.ADD_REQUEST:
            return {
                ...state,
                pending: true,
                tripSuccess: false
            }
        case tripConstants.ADD_SUCCESS:
            return {
                ...state,
                pending: false,
                error: '',
                trip: action.trip,
                serviceSelected: null,
                modalSelectScheduledPickUp: false,
                tripSuccess: true
            }
        case tripConstants.ADD_FAILURE:
            return {
                ...state,
                pending: false,
                trip: state.backupTrip,
                isReturn: false,
                originStop: null,
                requestTripError: true,
                modalSelectScheduledPickUp: false,
                error: action.error,
                tripSuccess: false
            }
        case tripConstants.GETBYID_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case tripConstants.GETBYID_SUCCESS:
            return {
                ...state,
                pending: false,
                error: '',
                trip: action.trip,
                results: [action.trip],
            }
        case tripConstants.GETBYID_FAILURE:
            return {
                ...state,
                pending: false,
                results: [],
                error: action.error,
            }
        case tripConstants.GETALL_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case tripConstants.GETALL_SUCCESS:
            return {
                ...state,
                pending: false,
                error: '',
                allTrips: action.allTrips,
                results: action.results,
            }
        case tripConstants.SET_FILTER_TRIPS:
            return {
                ...state,
                filterTrips: action.filters
            }
        case tripConstants.CLEAN_FILTER_TRIPS:
            return {
                ...state,
                filterTrips: [{
                    status: []
                }],
                tableFilters: [],
                requestTripError: false,
                tripSuccess: false
            }
        case tripConstants.GETALL_FAILURE:
            return {
                ...state,
                pending: false,
                results: [],
                error: action.error,
            }
        case tripConstants.UPDATE_DATE_TRIP:
            return {
                ...state,
                requestTrip: { ...state.requestTrip, requestPickUpStartTime: moment(action.date), pickupEndTime: moment(action.date).add(15, 'minutes') }
            }

        case tripConstants.GETALL_KPI_REQUEST:
            return {
                ...state,
                pendingTripsKPI: true,
            };
        case tripConstants.GETALL_KPI_SUCCESS:
            return {
                ...state,
                pendingTripsKPI: false,
                tripsKPI: action.results,
            };
        case tripConstants.GETALL_KPI_FAILURE:
            return {
                ...state,
                pendingTripsKPI: false,
                tripsKPI: [],
                error: action.error,
            };
        case tripConstants.GET_TRIP_SERVICES_REQUEST:
            const backupTrip = state.trip
            return {
                ...state,
                pending: true,
                originDistanceTime: null,
                destinationDistanceTime: null,
                trip: null,
                backupTrip: backupTrip
            }
        case tripConstants.GET_TRIP_SERVICES_SUCCESS:
            return {
                ...state,
                pending: false,
                error: '',
                services: action.services,
            }
        case tripConstants.GET_TRIP_SERVICES_FAILURE:
            return {
                ...state,
                pending: false,
                services: [],
                error: action.error,
                isReturn: false,
                trip: state.backupTrip
            }
        case tripConstants.SELECT_SERVICE_TRIP_OPEN:
            return {
                ...state,
                pending: false,
                modalSelectedService: true,
                requestTrip: action.trip,
                services: action.services,
                currentCustomer: action.customerId,
            }
        case tripConstants.SELECT_SERVICE_TRIP_CLOSE:
            return {
                ...state,
                modalSelectedService: false,
            }
        case tripConstants.DELETE_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case tripConstants.DELETE_SUCCESS:
            return {
                ...state,
                pending: false,
                results: state.results.filter(x => x.id !== action.id),
            }
        case tripConstants.DELETE_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            }
        case tripConstants.CLEAR_TRIP:
            return {
                ...state,
                trip: null,
                serviceLineDirection: null
            }
        case tripConstants.SET_MARKER:
            return {
                ...state,
                markers: [
                    ...state.markers,
                    {
                        ...state.markers[action.index],
                        position: action.position,
                        name: action.index,
                    },
                ],
            }
        case tripConstants.CLEAR_MARKER:
            return {
                ...state,
                markers: state.markers.filter(
                    item => item.name != action.index,
                ),
            }
        case tripConstants.CLEAR_ALL_MARKER:
            return {
                ...state,
                markers: [],
                modalSelectedService: false,
                originStop: null,
                destinationStop: null,
                services: [],
                serviceSelected: null,
                requestTripError: false
            }
        case tripConstants.GET_TRIPS_CUSTOMER_REQUEST:
            return {
                ...state,
                pending: true,
                originDistanceTime: null,
                destinationDistanceTime: null,
            }
        case tripConstants.GET_TRIPS_CUSTOMER_SUCCESS:
            return {
                ...state,
                pending: false,
                resultsTrips: action.trips,
                all: action.all,
                error: null,
            }
        case tripConstants.GET_TRIPS_CUSTOMER_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
                resultsTrips: [],
            }
        case tripConstants.SET_TRIP:
            return {
                ...state,
                trip: action.trip,
                originStop: null
            }
        case tripConstants.REJECT_TRIP_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case tripConstants.REJECT_TRIP_SUCCESS:
            return {
                ...state,
                pending: false,
                trip: null,
                originDistanceTime: null,
                destinationDistanceTime: null,
            }
        case tripConstants.REJECT_TRIP_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            }
        case tripConstants.ACCEPT_TRIP_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case tripConstants.ACCEPT_TRIP_SUCCESS:
            return {
                ...state,
                pending: false,
                trip: action.trip,
            }
        case tripConstants.ACCEPT_TRIP_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            }
        case tripConstants.CLOSE_MODAL_REQUEST_TRIP:
            return {
                ...state,
                modalMessage: false,
            }
        case tripConstants.OPEN_MODAL_REQUEST_TRIP:
            return {
                ...state,
                modalMessage: true,
                message: action.message,
            }
        case tripConstants.SET_SERVICE_NOT_SERVICE_LINE: {
            let my_origin = action.trip.pickUpLocation
            my_origin.point = {
                lat: my_origin.lat,
                lon: my_origin.lon,
            }
            my_origin.name = i18next.t('trips.maps.markers.myAddress')
            my_origin.id = 'my_address'
            let my_destination = action.trip.dropOffLocation
            my_destination.point = {
                lat: my_destination.lat,
                lon: my_destination.lon,
            }
            let possibleOrigins = []
            let possibleDestinations = []
            my_destination.name = i18next.t('trips.maps.markers.myAddress')
            my_destination.id = 'my_address'
            let service = action.service
            service.originZone.zoneStops.forEach(element => {
                possibleOrigins.push({ ...element })
            });
            if (!factory.existPointInStops(my_origin, service.originZone.zoneStops))
                possibleOrigins.push({ ...my_origin })
            service.destinationZone.zoneStops.forEach(element => {
                possibleDestinations.push({ ...element })
            });
            if (!factory.existPointInStops(my_destination, service.destinationZone.zoneStops))
                possibleDestinations.push({ ...my_destination })
            return {
                ...state,
                pending: false,
                message: action.message,
                modalMessage: true,
                serviceSelected: service,
                requestTrip: action.trip,
                customerId: action.customerId,
                possibleOrigins: possibleOrigins,
                possibleDestinations: possibleDestinations,
                serviceLine: action.serviceLine,
                originStop: null,
                destinationStop: null

            }
        }
        case tripConstants.SET_SERVICE_SERVICE_LINE: {
            let origins = []
            let destinations = []
            let servi = action.service
            let my_origin = action.trip.pickUpLocation
            my_origin.point = {
                lat: my_origin.lat,
                lon: my_origin.lon,
            }
            my_origin.outbound = true
            my_origin.name = i18next.t('trips.maps.markers.myAddress')
            my_origin.id = 'my_address'
            let my_destination = action.trip.dropOffLocation
            my_destination.point = {
                lat: my_destination.lat,
                lon: my_destination.lon,
            }
            my_destination.outbound = true
            my_destination.name = i18next.t('trips.maps.markers.myAddress')
            my_destination.id = 'my_address'
            if (servi.serviceType == SERVICES_TYPE.regularService) {
                servi.serviceLine.outbound.zoneStops.forEach(function (element, i) {
                    element.outbound = true
                    element.outboundIndex = i
                    element.allowOppositeDirections = servi.serviceLine.outbound.allowOppositeDirections
                    origins.push({ ...element })
                    destinations.push({ ...element })
                });
                servi.serviceLine.return.zoneStops.forEach(function (element, i) {
                    element.return = true
                    element.returnIndex = i
                    element.allowOppositeDirections = servi.serviceLine.return.allowOppositeDirections
                    origins.push({ ...element })
                    destinations.push({ ...element })
                });
            } else {
                servi.regularLines.forEach(function (line) {
                    line.outbound.zoneStops.forEach(function (stop) {
                        stop.outbound = true
                        if (!origins.find(item => item.id == stop.id))
                            origins.push({ ...stop })
                    })
                })
            }
            if (!window.selectAutoCompleteOrigin && !factory.existPointInStops(my_origin, origins))
                origins.push({ ...my_origin })
            if (!window.selectAutoCompleteDestination && !factory.existPointInStops(my_destination, destinations))
                destinations.push({ ...my_destination })
            return {
                ...state,
                pending: false,
                message: action.message,
                modalMessage: true,
                serviceSelected: servi,
                requestTrip: action.trip,
                customerId: action.customerId,
                possibleOrigins: origins,
                possibleDestinations: destinations,
                serviceLine: action.serviceLine
            }
        }
        case tripConstants.GET_POSSIBLE_DESTINATIONS_REG_LINE_SUCCESS: {
            let my_destination = state.possibleDestinations.find(item => item.id == 'my_address')
            if (my_destination)
                action.stops.push(my_destination)
            return {
                ...state,
                possibleDestinations: action.stops
            }
        }


        case tripConstants.SET_ORIGIN_DISTANCE_TIME:
            return {
                ...state,
                originDistanceTime: action.distanceTimeData,
            }
        case tripConstants.SET_DESTINATION_DISTANCE_TIME:
            return {
                ...state,
                destinationDistanceTime: action.distanceTimeData,
            }
        case tripConstants.SET_ORIGIN: {
            let service_selec = state.serviceSelected
            let origin = action.stop
            let allowDestinations = state.possibleDestinations
            let serviceLineDirection = null
            if (!state.serviceLine) {
                if (origin && service_selec.destinationZone.zoneType != 40)
                    allowDestinations =
                        allowDestinations.filter(
                            x => x.id != origin.id,
                        )
            } else {
                if (service_selec.serviceType == SERVICES_TYPE.regularService)
                    if (origin && origin.return) {
                        allowDestinations = Object.assign([], service_selec.serviceLine.return.zoneStops)
                        if (!origin.allowOppositeDirections)
                            allowDestinations = allowDestinations.splice(allowDestinations.indexOf(action.stop), allowDestinations.length)
                        allowDestinations = allowDestinations.filter(x => x.id != origin?.id)
                        serviceLineDirection = 'Return'

                    } else
                        if (origin && origin.outbound) {
                            allowDestinations = Object.assign([], service_selec.serviceLine.outbound.zoneStops)
                            if (!origin.allowOppositeDirections)
                                allowDestinations = allowDestinations.splice(allowDestinations.indexOf(action.stop), allowDestinations.length)
                            allowDestinations = allowDestinations.filter(x => x.id != origin?.id)
                            serviceLineDirection = 'Outbound'

                        } else
                            if (!origin)
                                allowDestinations = state.possibleOrigins
                            else
                                allowDestinations.push(state.originStop)
                else
                    serviceLineDirection = 'Outbound'
            }

            return {
                ...state,
                originStop: action.stop,
                serviceSelected: service_selec,
                possibleDestinations: allowDestinations,
                serviceLineDirection: serviceLineDirection
            }
        }
        case tripConstants.SET_DESTINATION:
            return {
                ...state,
                destinationStop: action.stop,
            }
        case tripConstants.GET_TRIPS_TYPES_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case tripConstants.GET_TRIPS_TYPES_SUCCESS:
            return {
                ...state,
                pending: false,
                typesTrips: action.results,
            }
        case tripConstants.GET_TRIPS_TYPES_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            }
        case tripConstants.SET_REPEAT_TRIP:
            return {
                ...state,
                openModalReturnTrip: true,
                tripToReturn: action.tripToReturn,
                isReturn: action.isReturn,
            }
        case tripConstants.CLEAN_REPEAT_TRIP:
            return {
                ...state,
                tripToReturn: null,
                openModalReturnTrip: false,
                isReturn: false,
                customerToRequest: null
            }
        case customerConstants.SET_ONLYONE_CUSTOMER:
            return {
                ...state,
                customerToRequest: action.customer,
            }
        case tripConstants.SET_MODIFY_TRIP:
            return {
                ...state,
                customerToRequest: action.customers,
                dropOffLocation: action.trip.dropOffLocation,
                pickUpLocation: action.trip.pickUpLocation,
                numPassengers: action.trip.numPassengers,
                wheelChairs: action.trip.wheelChairs,
                quantityLuggage: action.trip.quantityLuggage,
                tripToModify: action.trip,
            }
        case notificationsConstants.REQUEST_TRIP_FROM_CALL:
            return {
                ...state,
                dropOffLocation: action.request.dropOffLocation,
                pickUpLocation: action.request.pickUpLocation,
                pickUpDate: action.request.pickUpDate,
                customerToRequest: action.request.customerToRequest,
                numPassengers: action.request.numPassengers
            }
        case tripConstants.SET_FILTER_TRIPS_DATES:
            if (action.typeDate == 'startDate')
                return {
                    ...state,
                    filterStartDate: action.value,
                }
            else
                return {
                    ...state,
                    filterEndDate: action.value,
                }
        case tripConstants.OPEN_MODAL_SHOW_EXPEDITIONS:
            return {
                ...state,
                modalExpeditions: true,
                expeditions: action.expeditions
            }
        case tripConstants.GET_SCHEDULED_PICKUP_REG_LINE_SUCCESS:
            action.expeditions.sort((a, b) => a.hour.localeCompare(b.hour))
            return {
                ...state,
                modalSelectScheduledPickUp: true,
                posibleSchedule: action.expeditions
            }
        case tripConstants.CLOSE_MODAL_SELECT_SCHEDULE_PICKUP:
            return {
                ...state,
                modalSelectScheduledPickUp: false,
                posibleSchedule: []
            }
        case tripConstants.CLOSE_MODAL_SHOW_EXPEDITIONS:
            return {
                ...state,
                modalExpeditions: false
            }
        case tripConstants.ASSING_DRIVER_MANUAL_TRIP_REQUEST:
            return {
                ...state,
                pending: true
            }
        case tripConstants.ASSING_DRIVER_MANUAL_TRIP_SUCCESS:
            return {
                ...state,
                pending: false
            }
        case tripConstants.ASSING_DRIVER_MANUAL_TRIP_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case tripConstants.SAVE_TABLE_FILTERS:
            return {
                ...state,
                tableFilters: action.tableFilters
            }
        case tripConstants.SET_REQUEST_TRIP_INFO:
            return {
                ...state,
                dropOffLocation: action.object.dropOffLocation,
                pickUpLocation: action.object.pickUpLocation,
                pickUpDate: action.object.requestPickUpStartTime,
                customerToRequest: action.object.customerName,
                quantityLuggage: action.object.luggage,
                numPassengers: action.object.numPassengers,
                isRequestByDropOff: action.object.isRequestByDropOff
            }

        case tripConstants.CLOSE_RETURN_TRIP_MODAL:
            return {
                ...state,
                openModalReturnTrip: false,
            }
        default:
            return state
    }
}
