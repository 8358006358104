import { version } from '../../package.json'
const apiKey = process.env.REACT_APP_FIREBASE_APIKEY;
const authDomain = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
const databaseURL = process.env.REACT_APP_FIREBASE_DATABASE_URL;
const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID;
const storageBucket = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
const messagingSenderId = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
const appId = process.env.REACT_APP_FIREBASE_APP_ID;
const measurementId = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;
const vapidKey = process.env.REACT_APP_FIREBASE_VAPID_KEY;

const stringConstants = {
    URL_Refresh_Token: `https://securetoken.googleapis.com/v1/token?key=${apiKey}`,
    //URL_ApiGateway: process.env.REACT_APP_BASE_URL,
    URL_ApiGateway: window.location.origin != 'http://localhost:3000' ? window.location.origin.replace('admin', 'api') : process.env.REACT_APP_BASE_URL,
    ID_Aplication: apiKey,
    TIME_REFRESH_MAPS: 30000,
    TIME_REFRESH_VEHICLE_POSITION: 45000,
    TIME_NOTIFICATIONS: 300000, // 5 minutes
    TIME_TICKETING_NOTIFICATIONS: 600000, // 10 minutes
    API_Key_GoogleMaps: process.env.REACT_APP_GOOGLE_MAPS_KEY,
    firebaseConfig: {
        apiKey,
        authDomain,
        databaseURL,
        projectId,
        storageBucket,
        messagingSenderId,
        appId,
        measurementId,
    },
    vapidKey,
    version: version,
    MONGO_DB_NULL: "000000000000000000000000"
};

export default stringConstants;
