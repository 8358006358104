import React, { useCallback } from 'react'
import { isMultipleTripOutbound, outboundHasReturn } from '../../../tools'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { Box, Checkbox, Grid, IconButton, Tooltip } from '@material-ui/core'
import { GridCenter, stylesMultipleTrip } from '../../details'
import { ArrowForward } from '../../icons/ArrowForward'
import { ArrowBack } from '../../icons/ArrowBack'
import ErrorIcon from '@material-ui/icons/Error';
import DeleteIcon from '@material-ui/icons/Delete';
import ReplayIcon from '@material-ui/icons/Replay';
import { SERVICES_TYPE } from '../../../../../constants/types'
import i18next from 'i18next'

export const PlanificatedTripItem = ({ trip, handleClickTrip, handleRetryTrip, handleMouseOver, handleCancelTrip, tripToPreview, selectedTrips, handleChangeSelectTrip, disableCheck }) => {

    const checkPrintIcon = useCallback((trip) => {
        if (trip.tripResponse == null)
            return false
        return outboundHasReturn(trip.tripResponse, listOfTrips) || trip.tripResponse.isReturn
    }, [])

    const serviceType = useSelector((state) => state.multipleTripReducer.serviceType)
    const listOfTrips = useSelector((state) => state.multipleTripReducer.results)
    const areInReturnProcess = useSelector((state) => state.multipleTripReducer.areInReturnProcess)

    const classes = stylesMultipleTrip()

    return (
        <React.Fragment key={trip.tripId} >
            <Grid item md={12} sm={12} className={`${classes.divContainerTrip} ${classes.border} ${tripToPreview?.tripId == trip.tripId ? `${classes.tripSelected}` : ''} ${trip.tripResponse?.isReturn ? `${classes.separatorTrip}` : ''}`} onClick={(event) => handleClickTrip(event, trip)}  >
                <Grid item xl={3} md={2} className={`${classes.center}`}>
                    {!outboundHasReturn(trip.tripResponse, listOfTrips) && !trip.tripResponse?.isReturn ?
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            minHeight={60}
                        >
                            <Checkbox
                                disabled={disableCheck(trip)}
                                checked={selectedTrips.find(item => item.id == trip.tripId)?.checked}
                                onChange={(event) => handleChangeSelectTrip(event, trip.tripResponse)}
                                color='primary' />
                        </Box>
                        : null
                    }
                </Grid>

                <GridCenter key={trip.tripId + '_date'}>
                    <Grid item className={classes.colorPrimary}>
                        {
                            checkPrintIcon(trip) && (isMultipleTripOutbound(trip.tripResponse) ? ArrowForward() : ArrowBack())
                        }
                    </Grid>
                    <Grid item style={{ padding: 13 }}>
                        {moment(trip.hasErrors ? trip.requestPickUpTime : trip.tripResponse.schedulePickUpTime).format("DD/MM/YYYY HH:mm")}
                    </Grid>
                </GridCenter>
                <GridCenter key={trip.tripId + '_detail'}>
                    {
                        trip.hasErrors ?
                            <>
                                <Tooltip title={i18next.t('trips.multipleBook.showErrorTooltip')}>
                                    <IconButton onClick={() => handleMouseOver(trip)}>
                                        <ErrorIcon color="error" />
                                    </IconButton>
                                </Tooltip>
                                {
                                    // prevent retry when we dont't have options to choose and the service is regularWithSchedule
                                    !(SERVICES_TYPE.regularWithSchedule === serviceType && trip.pickupHours?.length === 0) && (
                                        <Tooltip title={i18next.t('trips.multipleBook.retryTripTooltip')}>
                                            <IconButton onClick={() => handleRetryTrip(trip)}>
                                                <ReplayIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )
                                }
                            </>
                            :
                            <Tooltip title={i18next.t('customers.trips.page.actions.delete')}>
                                <span>
                                    <IconButton disabled={(moment(trip.tripResponse?.schedulePickUpTime).isBefore(moment()) || areInReturnProcess) ?? false}>
                                        <DeleteIcon onClick={() => handleCancelTrip(trip)} />
                                    </IconButton>
                                </span>
                            </Tooltip>

                    }
                </GridCenter>
            </Grid>
        </React.Fragment>
    )
}