import { customerConstants } from '../../constants/customer_constants';
import { servicesConstants } from '../../constants/services_constants';
import { tripConstants } from '../../constants/trip_constants';
import { userConstants } from '../../constants/user_constants';

let initialState = {
    pending: false,
    results: [],
    relatedCustomers: [],
    customerAddresses: [],
    isSubmittingGDPR: false,
    favouriteAddresses: [],
    customersKPI: [],
    currentPage: 0,
    documentTypes: [],
    tableState: [],
    isMenuOpen: true,
};

export function customerReducer(state = initialState, action) {
    switch (action.type) {
        case customerConstants.FILTER_REQUEST:
            return {
                ...state,
                pending: true
            };
        case customerConstants.FILTER_SUCCESS:
            return {
                ...state,
                pending: false,
                results: action.results
            };
        case customerConstants.FILTER_FAILURE:
            return {
                ...state,
                pending: false,
                results: [],
                error: action.error
            };
        case customerConstants.GETBYID_REQUEST:
            return {
                ...state,
                pending: true
            };
        case servicesConstants.SET_ABSENCES_SUCCESS:
            const customer = state.customer
            customer.absences = action.absences
            return {
                ...state
            };
        case customerConstants.GETBYID_SUCCESS:
            return {
                ...state,
                customer: action.customer,
                relatedCustomers: action.customer.relatedCustomers,
                pending: false
            };
        case customerConstants.GETBYID_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            };
        case customerConstants.GETALL_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case customerConstants.GETALL_SUCCESS:
            return {
                ...state,
                pending: false,
                results: action.results
            };
        case customerConstants.GETALL_FAILURE:
            return {
                ...state,
                pending: false,
                results: [],
                error: action.error
            };
        case customerConstants.CLEAR_RESULTS:
            return {
                ...state,
                results: [],
                pending: false
            };
        case customerConstants.ADD_REQUEST:
            return {
                ...state,
                pending: true
            };
        case customerConstants.ADD_SUCCESS:
            return {
                ...state,
                pending: false,
                customer: action.customer
            };
        case customerConstants.ADD_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            };
        case customerConstants.DELETE_REQUEST:
            return {
                ...state,
                pending: true
            };
        case customerConstants.DELETE_SUCCESS:
            return {
                ...state,
                pending: false,
                results: state.results.filter(x => x.id !== action.id)
            };
        case customerConstants.DELETE_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            };
        case userConstants.ADD_RELATEDCUSTOMER:
            return {
                ...state,
                relatedCustomers: [...state.relatedCustomers, action.customer]
            };
        case userConstants.REMOVE_RELATEDCUSTOMER:
            return {
                ...state,
                relatedCustomers: state.relatedCustomers.filter(item => item.tableData.id != action.customer.tableData.id)
            };
        case userConstants.EDIT_RELATEDCUSTOMER:
            return {
                ...state,
                relatedCustomers: state.relatedCustomers.map(
                    (content, i) => action.customer.tableData.id === content.tableData.id ? { ...action.customer }
                        : content
                )
            };
        case userConstants.OPEN_MODAL:
            return {
                ...state,
                add: action.typeOperation === "add" ? true : false,
                edit: action.typeOperation === "edit" ? true : false,
                userEdit: action.typeOperation === "edit" ? action.user : null
            };
        case customerConstants.SET_CUSTOMER:
            return {
                ...state,
                currentCustomer: action.id,
                countMax: action.countMax,
                fullName: action.fullName,
                address: action.address,
                name: action.name,
                surname: action.surname,
                favouriteAddresses: action.favouriteAddresses,
                customerBirthDate: action.customerDateOfBirth,
                relatedCustomers: action.relatedCustomers,
                langNotification: action.lang,
                customerPushToken: action.pushToken,
                hasWheelChair: action.hasWheelChair
            };
        case customerConstants.REFRESH_CUSTOMER:
            return {
                ...state,
                pending: false,
                favouriteAddresses: action.customer.favouriteAddresses,
                address: action.customer.addressPosition,
            };
        case customerConstants.RESET_PSW_REQUEST:
            return {
                ...state,
                pending: true
            };
        case customerConstants.RESET_PSW_SUCCESS:
            return {
                ...state,
                pending: false
            };
        case customerConstants.RESET_PSW_FAILURE:
            return {
                ...state,
                pending: false
            };
        case customerConstants.CHECK_PROFILE_REQUEST:
            return {
                ...state,
                pending: true
            };
        case customerConstants.CHECK_PROFILE_SUCCESS:
            return {
                ...state,
                pending: false,
                canOperate: action.result
            };
        case customerConstants.CHECK_PROFILE_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            };
        case customerConstants.UPDATE_REQUEST:
            return {
                ...state,
                pending: true
            };
        case customerConstants.UPDATE_SUCCESS:
            return {
                ...state,
                pending: false,
                customer: action.customer
            };
        case customerConstants.UPDATE_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            };
        case userConstants.GET_PERSONAL_INFO_REQUEST:
            return {
                ...state,
                isSubmittingGDPR: true,
            };
        case userConstants.GET_PERSONAL_INFO_SUCCESS:
            return {
                ...state,
                isSubmittingGDPR: false,
                GDPRurlFile: action.data
            };
        case userConstants.GET_PERSONAL_INFO_FAILURE:
            return {
                ...state,
                isSubmittingGDPR: false,
                error: action.error
            };
        case customerConstants.CUSTOMER_ADDRESSES_SUCCESS:
            return {
                ...state,
                customerAddresses: action.results
            };
        case tripConstants.SET_REPEAT_TRIP:
            return {
                ...state,
                currentCustomer: action.tripToReturn.customerId,
                fullName: action.tripToReturn.fullName,
                favouriteAddresses: [],
                relatedCustomers: action.tripToReturn.customerToRepeat

            };
        case customerConstants.CUSTOMER_SET_PAGE_TABLE:
            return {
                ...state,
                currentPage: action.page
            };
        case customerConstants.CUSTOMER_CLEAN_RELATED:
            return {
                ...state,
                relatedCustomers: []
            }
        case tripConstants.SET_MODIFY_TRIP:
            return {
                ...state,
                favouriteAddresses: [],
                relatedCustomers: action.customers
            }
        case customerConstants.CUSTOMER_GET_KPI_REQUEST:
            return {
                ...state,
                pending: true
            }
        case customerConstants.CUSTOMER_GET_KPI_SUCCESS:
            return {
                ...state,
                pending: false,
                customersKPI: action.results
            }
        case customerConstants.CUSTOMER_GET_KPI_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case customerConstants.GET_DOCUMENT_TYPES_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case customerConstants.GET_DOCUMENT_TYPES_SUCCESS:
            return {
                ...state,
                pending: false,
                documentTypes: action.data
            }
        case customerConstants.GET_DOCUMENT_TYPES_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case customerConstants.SAVE_TABLE_STATE:
            return {
                ...state,
                tableState: action.tableState
            }
        case userConstants.TOGGLE_MENU:
            return {
                ...state,
                isMenuOpen: !state.isMenuOpen
            }
        default:
            return state;
    }
}